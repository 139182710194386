import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { LoadingButton } from '@mui/lab'
import { Container, Stack, Typography, Button, Card } from '@mui/material'

import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'

import useAxios from 'hooks/useAxios'
import { hasAccess } from 'utils'

import { LIST_REIMBURSEMENT } from 'services/url'
import DetailView from 'pages/Reimbursement/DetailView'
import { REIMBURSEMENT } from 'utils/helmet'
import CustomInput from 'sections/common/CustomInput'
import model from 'sections/models/reimbursement'
import useUser from 'hooks/useUser'
import useQueryParamsState from 'hooks/useQueryParamsState'
import PropTypes from 'prop-types'

export default function SupportTicketPage({ access }) {
  const [search, setSearch] = useQueryParamsState({
    category: '',
  })

  const { page, rowsPerPage } = useParams()
  const userData = useUser()
  const filterUser = useMemo(() => (hasAccess(access, 'admin') ? '' : userData.email), [access, userData.email])
  const [selectedData, setSelectedData] = useState({})
  const [paginator, setPaginator] = useState({ page: page || 1, rowsPerPage: rowsPerPage || 10, totalCount: 0 })

  const { sendRequest, data, error, loading } = useAxios()

  const columns = useMemo(
    () => [
      { id: 'user_email', label: 'User', alignRight: true, align: 'left' },
      { id: 'title', label: 'Title', alignRight: true, align: 'center' },
      { id: 'reason', label: 'Reason', alignRight: true, align: 'center' },
      { id: 'category', label: 'Category', alignRight: true, align: 'center' },
      { id: 'rate', label: 'Rate', alignRight: true, align: 'center' },
    ],
    []
  )

  const fetchAll = useCallback(
    () => sendRequest(LIST_REIMBURSEMENT(paginator.rowsPerPage, paginator.page, search.category, filterUser)),
    [search.category, filterUser, paginator.page, paginator.rowsPerPage]
  )

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    if (data) {
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: data.count }))
    } else if (error) {
      alert('Something is not correct with the Reimbrusement request')
    }
  }, [data, error])

  const searchBtn = () => {
    if (paginator.page === 1) {
      fetchAll()
    } else {
      // NOTE: if not page 1 then
      // Changing page itself triggers fetchAll from useEffect
      setPaginator((prev) => ({ ...prev, page: 1 }))
    }
  }

  useEffect(() => {
    fetchAll()
  }, [paginator.page])

  const resetFilter = () => setSearch({ category: '' })

  const handleChange = (e) => {
    const { name: field, value } = e.target
    setSearch({ [field]: value })
  }

  const renderSearchButtons = useMemo(
    () => (
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2} gap={2}>
        <CustomInput
          key={'category'}
          name={'category'}
          formValue={search.category}
          {...model.category}
          handleInputChange={handleChange}
        />
        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            sx={{ mx: 1 }}
            color="primary"
            variant="contained"
            onClick={() => searchBtn()}
            startIcon={<Iconify icon="eva:search-fill" />}
          >
            Search
          </Button>
          <Button
            sx={{ mx: 1 }}
            color="warning"
            variant="contained"
            onClick={resetFilter}
            startIcon={<Iconify icon="eva:close-fill" />}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    ),
    [search.category]
  )

  const renderMainContent = useMemo(() => {
    if (loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data?.results.length > 0) {
      return (
        <TableView
          data={data.results}
          columns={columns}
          selected={[]}
          handleSelect={() => []}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }, [loading, data, columns, paginator.page, paginator.totalCount, paginator.rowsPerPage])

  return (
    <>
      <Helmet>
        <title>{REIMBURSEMENT}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">REIMBURSEMENT</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={loading}
              variant="contained"
              onClick={() => fetchAll()}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setSelectedData({ title: '' })}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Request
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons}</Card>

        <Card>
          <SimpleToolbar numSelected={0} totalCount={paginator.totalCount} />
          {renderMainContent}
        </Card>

        <DetailView
          key={selectedData?.id || 'new'}
          access={access}
          selectedData={selectedData}
          fetchData={fetchAll}
          close={() => setSelectedData({})}
        />
      </Container>
    </>
  )
}

SupportTicketPage.propTypes = {
  access: PropTypes.number,
}
