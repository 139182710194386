export default {
  designation: {
    label: 'Designation',
    type: 'string',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  email: {
    label: 'Email Address',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  name: {
    label: 'Name',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  phone_number: {
    label: 'Phone Number',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    restriction: {
      maxLength: 50,
    },
  },
  point_of_contact: {
    label: 'Point Of Contact',
    type: 'boolean',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
}
