import { isValidEmail } from '../../utils/inputValidations'

function validateField(fieldName, value, fieldData) {
  if (fieldData.isRequired) {
    if (fieldData.type === 'email' || fieldName.toLowerCase().includes('email')) {
      const temp = value.split(',')
      return temp.some(val => isValidEmail(val))
    }
    if (fieldData.type === 'number') return true
    return value.trim() !== ''
  }
  return true
}

export function validateData(data, model) {
  const errors = {}

  Object.entries(model).forEach(([fieldName, fieldData]) => {
    const fieldValue = data[fieldName]
    if (!validateField(fieldName, fieldValue, fieldData)) {
      errors[fieldName] = `${fieldData.label} is required`
    }
  })

  return [errors, Object.keys(errors).length === 0]
}
