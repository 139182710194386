import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import CustomInput from 'sections/common/CustomInput'
import DetailView from 'sections/common/DetailView'
import userModel from 'sections/models/user'
import { Grid, Paper, MenuItem, Typography } from '@mui/material'
import { initialFormValues } from 'utils/common'
import useUser from 'hooks/useUser'
import { getUsersChoice, updateUserById, createUser } from 'services/user'
import { LoadingButton } from '@mui/lab'
import { validateForm } from 'sections/validator'

const USER_FIELDS = Object.entries(userModel)

const ManageUser = ({ access, data, fetchData, close }) => {
  const user = useUser()
  const isSelf = data?.email === user.email
  const [formValues, setFormValues] = useState({})
  const [errors, setErrors] = useState({})
  const [emailChoice, setEmailChoice] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setErrors({})
    setLoading(false)
    setFormValues({ ...initialFormValues(data, userModel) })
  }, [data])

  const handleChange = (e) => {
    const value = e.target.name === 'is_suspend' ? e.target.checked : e.target.value
    setFormValues((prev) => ({ ...prev, [e.target.name]: value }))
  }

  useEffect(() => {
    getUsersChoice().then((res) => setEmailChoice(res.emails.map((email) => ({ label: email, value: email }))))
  }, [])

  const handleSubmit = async () => {
    try {
      await validateForm(userModel, formValues, setErrors)
      const shouldUpdate = Object.keys(formValues).some((key) => data[key] !== formValues[key])
      if (!shouldUpdate) return
      const promise = data.id ? updateUserById(data.id, formValues) : createUser(formValues)
      setLoading(true)
      const response = await promise
      if (response?.success) {
        fetchData()
        close()
        alert(data.id ? 'User Updated Successfully' : 'User Created Successfully')
      }
      setLoading(false)
    } catch (error) {
      console.error('validation failed')
    }
  }

  useEffect(() => {
    userModel.hr.optionList = emailChoice
    userModel.manager.optionList = emailChoice
  }, [emailChoice])

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title={data.first_name ? `Manage ${data.first_name} Details` : 'Create New User'}
      body={
        <>
          <Paper elevation={3} sx={{ p: 5 }}>
            <Grid container item xs={12} spacing={1}>
              {Object.keys(userModel)
                .filter((field) => field !== 'is_suspended')
                .map((field) => (
                  <Grid item xs={12} sm={4} key={field} my={'auto'}>
                    <CustomInput
                      name={field}
                      {...userModel[field]}
                      formValue={formValues[field]}
                      handleInputChange={handleChange}
                      isDisabled={isSelf || userModel[field]?.isDisabled}
                      errors={errors}
                    />
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </>
      }
      actions={
        <>
          {isSelf ? (
            <></>
          ) : (
            <LoadingButton
              sx={{ mr: 2 }}
              loading={loading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="primary"
            >
              SUBMIT
            </LoadingButton>
          )}
        </>
      }
    />
  )
}

ManageUser.propTypes = {
  access: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default ManageUser
