import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { Button } from '@mui/material'

export default function JsonToExcelConverter({ jsonData, fileName = 'download', cta }) {
  const [loading, setLoading] = useState(false)

  const handleDownload = () => {
    try {
      if (loading) return
      setLoading(true)

      console.log(jsonData)

      // Create a new workbook and add a worksheet
      const worksheet = XLSX.utils.json_to_sheet(jsonData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      // Create Excel file and trigger download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' })

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.download = `${fileName}.xlsx`
      link.click()

      // Clean up
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error generating Excel file:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Button variant="contained" onClick={handleDownload}>
        {cta || 'Download Excel File'}
      </Button>
    </div>
  )
}

JsonToExcelConverter.propTypes = {
  jsonData: PropTypes.array.isRequired,
  fileName: PropTypes.string,
  cta: PropTypes.string,
  setLoading: PropTypes.func,
}
