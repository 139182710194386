export const FORM_FIELDS = [
  ['is_referred_consultant', 'Referred'],
  ['is_hotlist', 'Hot List'],
  ['is_signature', 'Signature'],
  ['is_available', 'Available'],
  ['state_experience', 'State Experience'],
  ['record_status', 'Status'],
  ['author', 'Author'],
  ['reviewer_comments', 'Reviewer Comments'],
  ['recruiter_remarks', 'Recruiter Remarks'],
  ['full_name', 'Full Name'],
  ['personal_email', 'Email'],
  ['mobile', 'Mobile'],
  ['address', 'Address'],
  ['zipcode', 'Zip Code'],
  ['city', 'City'],
  ['state', 'State'],
  ['country', 'Country'],
  ['gender', 'Gender'],
  ['labour_category', 'Labour Category'],
  ['ethnicity', 'Ethnicity'],
  ['nationality', 'Nationality'],
  ['preferred_employee_type', 'Preferred Employee Type'],
  ['work_authorization', 'Work Authorization'],
  ['linkedin_profile', 'LinkedIn Profile'],
  ['documents_shared', 'Documents Shared'],
  ['pending_documents', 'Pending Documents'],
  ['referral_name', 'Referral Name'],
  ['core_strength', 'Core Strength'],
  ['profile_sourced_from', 'Profile Sourced From'],
  ['matching_requirements', 'Matching Requirements'],
]

export const SUBMISSION_FIELDS = [
  ['vendor_consultant_id', 'Vendor Consultant'],
  ['client_id', 'Client ID'],
  ['applied_position_title', 'Applied Position Title'],
  ['jd_requirement', 'Applied JD Req'],
  ['bill_rate', 'Bill Rate'],
  ['submission_date', 'Submission Date'],
  ['interview_date', 'Interview Date'],
  ['onboard_date', 'Onboard Date'],
  ['last_working_date', 'Last Working Date'],
  ['client_feedback', 'Client Feedback'],
  ['onboard_status', 'Onboard Status'],
  ['comments', 'Comments'],
]

export const VENDOR_CONSULTANT_FIELDS = [
  ['vendor_id', 'Vendor ID'],
  ['pay_rate', 'Rate'],
]

export const SUBMISSION_FIELD_DEFINITION = {
  id: 'string',
  client_id: 'string',
  submission_date: 'date',
  interview_date: 'date',
  onboard_date: 'date',
  last_working_date: 'date',
  vendor_consultant_id: 'string',
  bill_rate: 'string',
  applied_position_title: 'string',
  jd_requirement: 'string',
  client_feedback: 'string',
  onboard_status: 'string',
  comments: 'string',
}

export const FIELD_DEFINITION = {
  personal_email: 'string',
  author: 'string',
  full_name: 'string',
  gender: 'string',
  is_signature: 'boolean',
  state_experience: 'boolean',
  is_hotlist: 'boolean',
  record_status: 'string',
  mobile: 'string',
  address: 'string',
  zipcode: 'string',
  city: 'string',
  state: 'string',
  recruiter_remarks: 'string',
  reviewer_comments: 'string',
  country: 'string',
  ethnicity: 'string',
  nationality: 'string',
  preferred_employee_type: 'string',
  work_authorization: 'string',
  linkedin_profile: 'string',
  documents_shared: 'string',
  pending_documents: 'string',
  referral_name: 'string',
  labour_category: 'string',
  profile_sourced_from: 'string',
  core_strength: 'string',
  matching_requirements: 'string',
  job_title: 'string',
  certification: 'array',
  skills: 'array',
}

export const VENDOR_FIELDS_DEFINITION = {
  id: 'string',
  vendor_id: 'string',
  pay_rate: 'string',
}

export const INITIAL_FORM_VALUES = (data, definition) =>
  Object.keys(definition).reduce((acc, fieldName) => {
    const fieldType = definition[fieldName]

    switch (fieldType) {
      case 'string':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'boolean':
        acc[fieldName] = data[fieldName] || false
        break
      case 'integer':
        acc[fieldName] = data[fieldName] || 0
        break
      case 'date':
        acc[fieldName] = data[fieldName] || ''
        break
      case 'array':
        acc[fieldName] = data[fieldName] || []
        break
      default:
        acc[fieldName] = data[fieldName] || ''
    }

    return acc
  }, {})

export const ETHNICITY = [
  { label: 'Asia', value: 'Asia' },
  { label: 'USA', value: 'USA' },
  { label: 'India', value: 'India' },
  { label: 'South Africa', value: 'South_Africa' },
  { label: 'Nigerian', value: 'Nigerian' },
]

export const MARITAL_CHOICE = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Separate', value: 'Separate' },
]

export const EMPLOYEE_TYPE_CHOICE = [
  { label: 'w2 with benefits', value: 'w2_with_benefits' },
  { label: 'w2 without benefits', value: 'w2_without_benefits' },
  { label: 'C2C', value: 'C2C' },
  { label: 'C2H', value: 'C2H' },
  { label: '1099', value: '1099' },
  { label: 'Internship', value: 'Internship' },
]

export const WORK_AUTH_CHOICE = [
  { label: 'H1B', value: 'H1B' },
  { label: 'OPT EAD', value: 'OPT_EAD' },
  { label: 'H4 EAD', value: 'H4_EAD' },
  { label: 'GC', value: 'GC' },
  { label: 'USC', value: 'USC' },
  { label: 'TN', value: 'TN' },
  { label: 'H1', value: 'H1' },
  { label: 'L1', value: 'L1' },
  { label: 'L2', value: 'L2' },
  { label: 'CPT', value: 'CPT' },
  { label: 'GC EAD', value: 'GC_EAD' },
]

export const PROFILE_SOURCED_CHOICE = [
  { label: 'Job board', value: 'Job_board' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'Direct Applicant', value: 'Direct_applicant' },
  { label: 'Social Network', value: 'Social_network' },
  { label: 'Sub Vendor', value: 'Sub_vendor' },
  { label: 'Job Applicant', value: 'Job_applicant' },
  { label: 'Campus Recruit', value: 'Campus_recruit' },
  { label: 'Intern', value: 'Intern' },
]

export const LABOUR_CATEGORY = [
  { label: 'Architect', value: 'architect' },
  { label: 'Project Manager', value: 'project_manager' },
  { label: 'Java Developer', value: 'java_developer' },
  { label: 'Other Developers', value: 'other_developers' },
  { label: 'Security Engineer', value: 'security_engineer' },
  { label: 'QA Test Engineer', value: 'qa_test_engineer' },
  { label: 'Adminstrator', value: 'adminstrator' },
  { label: 'Data Engineer', value: 'data_engineer' },
  { label: 'IT Help Desk', value: 'it_help_desk' },
  { label: 'Other Adminstrators', value: 'other_adminstrators' },
  { label: 'Business Intelligence', value: 'business_intelligence' },
  { label: 'Business Analyst', value: 'business_analyst' },
]

export const GENDER = [
  { label: 'Others', value: 'Others' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
]

const ONBOARD_STATUS_CHOICE = [
  { label: 'Interview', value: 'interview' },
  { label: 'Joined', value: 'joined' },
  { label: 'Confirmed to Join', value: 'confirm_to_join' },
  { label: 'Not joined', value: 'not_joined' },
]

const CLIENT_FEEDBACK_CHOICE = [
  { label: 'Yet to Confirm', value: 'yet_to_confirm' },
  { label: 'Shortlisted', value: 'shortlisted' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'On hold', value: 'onhold' },
]

export const SUBMISSION_FIELD_TYPES = {
  disable: ['applied_position_title'],
  option: {
    onboard_status: ONBOARD_STATUS_CHOICE,
    client_feedback: CLIENT_FEEDBACK_CHOICE,
    vendor_consultant_id: [],
  },
  datePicker: ['submission_date', 'interview_date', 'onboard_date', 'last_working_date'],
  required: [
    'client_id',
    'submission_date',
    'bill_rate',
    'jd_requirement',
    'client_feedback',
    'onboard_status',
    'vendor_consultant_id',
  ],
}

export const CONSULTANT_FIELD_TYPES = {
  disable: ['record_status', 'author'],
  boolean: ['is_signature', 'is_hotlist', 'is_available', 'is_referred_consultant', 'state_experience'],
  option: {
    gender: GENDER,
    labour_category: LABOUR_CATEGORY,
    ethnicity: ETHNICITY,
    work_authorization: WORK_AUTH_CHOICE,
    profile_sourced_from: PROFILE_SOURCED_CHOICE,
    preferred_employee_type: EMPLOYEE_TYPE_CHOICE,
  },
  required: [
    'personal_email',
    'full_name',
    'job_title',
    'gender',
    'labour_category',
    'ethnicity',
    'preferred_employee_type',
    'work_authorization',
    'profile_sourced_from',
  ],
}

export const VENDOR_CONSULTANT_FIELD_TYPES = {
  option: {},
  required: ['vendor_id', 'pay_rate'],
}

export const APPROVED = 'Approved'
export const CATEGORY_APPROVED = `&record_status=${APPROVED}`

export const CATEGORY = [
  'ALL',
  'Hotlist',
  'Signature',
  'Review',
  'Referred',
  'Internship',
  'My Records',
  'Active Consultants',
]

export const formStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  gap: '0px 16px',
  alignItems: 'center',
}
