import { useEffect, useState, useMemo } from 'react'
import { Paper } from '@mui/material'
import useAxios from 'hooks/useAxios'
import { SUBMISSION_BY_CLIENT } from 'services/url'
import DetailView from 'sections/common/DetailView'

import TableView from 'sections/table/TableView'

export default function SubmissionVyClient({ access, clientId, show, close }) {
  const columns = useMemo(
    () => [
      { id: 'submission_date', label: 'Submission Date', alignRight: true, align: 'start' },
      { id: 'job_title', label: 'Job Title', alignRight: false, align: 'start' },
      { id: 'client_feedback', label: 'Client Feedback', alignRight: false, align: 'start' },
      { id: 'onboard_status', label: 'Onboarding Status', alignRight: false, align: 'start' },
    ],
    []
  )
  const { sendRequest, data, loading } = useAxios()

  useEffect(() => {
    sendRequest(SUBMISSION_BY_CLIENT(clientId))
  }, [])

  if (loading) return null

  return (
    <DetailView
      access={access}
      open={show}
      onClose={close}
      title={`Submission Done So Far`}
      maxWidth={'lg'}
      body={
        <>
          <Paper elevation={3} sx={{ px: 2, mb: 1, py: 1 }}>
            {data && (
              <TableView
                hidePagination
                data={data}
                columns={columns}
                showEdit={false}
                selected={[]}
                handleSelect={() => {}}
                paginator={{}}
                setPaginator={() => {}}
                handleEdit={() => {}}
              />
            )}
          </Paper>
        </>
      }
      actions={<></>}
    />
  )
}
