import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Stack, Typography, Button, Card, TextField } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import { useLocation } from 'react-router-dom'
import useAxios from 'hooks/useAxios'
import { LIST_CLIENT } from 'services/url'
import DetailView from 'pages/Client/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { LoadingButton } from '@mui/lab'
import { Helmet } from 'react-helmet-async'
import { CLIENT } from 'utils/helmet'
import Submission from 'pages/Client/Submission'

export default function ClientPage({ access }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const clientFilter = queryParams.get('client_name') || ''

  const [clientSubmission, setClientSubmission] = useState({})
  const [data, setData] = useState([])
  const [query, setQuery] = useState(clientFilter)
  const [selectedData, setSelectedData] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })

  const clientRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  useEffect(() => {
    if (query === '') {
      fetchAllClients()
    }
  }, [query])

  useEffect(() => {
    fetchAllClients()
  }, [paginator.page, paginator.rowsPerPage])

  const fetchAllClients = () => {
    const getClientList = LIST_CLIENT(paginator.rowsPerPage, paginator.page, query ? `&name=${query}` : '')
    clientRequest.sendRequest(getClientList)
  }

  const handleNewClient = useCallback(() => {
    setSelectedData({ name: '' })
  }, [])

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
  }, [])

  useEffect(() => {
    if (clientRequest.data) {
      setData(clientRequest.data.results)
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: clientRequest.data.count }))
    } else if (clientRequest.error) {
      console.error('Something is not correct with the client request')
    }
  }, [clientRequest.response])

  const resetFilter = () => {
    setQuery('')
  }

  const handleSearch = () => {
    fetchAllClients()
  }

  const customHandler = [
    {
      label: 'Submissions List',
      icon: 'simple-icons:formspree',
      action: (data) => {
        setClientSubmission(data.id)
      },
    },
  ]

  const renderSearchButtons = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2}>
      <TextField
        name="name"
        label="Name"
        multiline
        fullWidth
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          onClick={handleSearch}
          startIcon={<Iconify icon="eva:search-fill" />}
        >
          Search
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={resetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  )

  const renderMainContent = () => {
    if (clientRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }

    if (data.length > 0) {
      return (
        <TableView
          data={data}
          columns={columns}
          selected={selected}
          handleSelect={handleSelect}
          paginator={paginator}
          setPaginator={setPaginator}
          handleEdit={setSelectedData}
          customHandler={customHandler}
        />
      )
    }

    return (
      <>
        <Typography variant="h6" textAlign="center" p={1}>
          No Results Found on DB
        </Typography>
        <Typography variant="body1" textAlign="center" p={5}>
          Update your search fields or Hit Reset Button
        </Typography>
      </>
    )
  }

  const columns = useMemo(() => [{ id: 'name', label: 'Name', alignRight: false }], [])

  return (
    <>
      <Helmet>
        <title> {CLIENT} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Clients</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <LoadingButton
              sx={{ mr: 2 }}
              color="primary"
              loading={clientRequest.loading}
              variant="contained"
              onClick={fetchAllClients}
              startIcon={<Iconify icon="zondicons:refresh" />}
            >
              Sync DB
            </LoadingButton>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleNewClient}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Client
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ mb: 2 }}>{renderSearchButtons()}</Card>

        <Card>
          <SimpleToolbar numSelected={selected.length} totalCount={paginator.totalCount} />
          {renderMainContent()}
        </Card>

        <DetailView
          key={selectedData?.name}
          access={access}
          data={selectedData}
          fetchData={fetchAllClients}
          close={handleCloseDetailPage}
        />
        {Object.keys(clientSubmission).length > 0 && (
          <Submission
            access={access}
            clientId={clientSubmission}
            show={!!clientSubmission}
            close={() => setClientSubmission({})}
          />
        )}
      </Container>
    </>
  )
}
