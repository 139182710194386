export default {
  is_suspended: {
    label: 'Suspend User',
    type: 'boolean',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  employee_id: {
    label: 'Emp ID',
    type: 'string',
    isRequired: false,
    isDisabled: true,
    optionList: null,
    isMultiSelect: false,
  },
  email: {
    label: 'Email Address',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  first_name: {
    label: 'First Name',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  middle_name: {
    label: 'Middle Name',
    type: 'string',
    isRequired: false,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  last_name: {
    label: 'Last Name',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  gender: {
    label: 'Gender',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      { label: 'Others', value: 'Others' },
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
    ],
    isMultiSelect: false,
  },
  job_title: {
    label: 'Job Title',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      { label: 'CAE_INTERN', value: 'CAE_Intern' },
      { label: 'SENIOR_PROPOSAL_WRITER', value: 'Senior_Proposal_Writer' },
      { label: 'TA_SPECIALIST', value: 'TA_Specialist' },
      { label: 'ACCOUNTS_EXECUTIVE', value: 'Accounts_Executive' },
      { label: 'TA_MANAGER', value: 'TA_Manager' },
      { label: 'DIRECTOR', value: 'Director' },
      { label: 'SAP_ABAP', value: 'SAP_ABAP' },
      { label: 'TA_ACCOUNTANT', value: 'TA_Accountant' },
      { label: 'BDM_ANALYST', value: 'BDM_Analyst' },
      { label: 'TA_ASSOCIATE', value: 'TA_Associate' },
      { label: 'DATA_ENGINEER', value: 'Data_Engineer' },
      { label: 'PRODUCT_OWNER_CHILD_SUPPORT', value: 'Product_Owner_Child_Support' },
      { label: 'HEAD_HR_SALES', value: 'Head_HR_Sales' },
      { label: 'MANAGER_HR_OPS', value: 'Manager_HR_Ops' },
      { label: 'JAVA_FULL_STACK_DEVELOPER', value: 'Java_Full_Stack_Developer' },
      { label: 'SR_JAVA_FULL_STACK_DEVELOPER', value: 'Sr_Java_Full_Stack_Developer' },
    ],
    isMultiSelect: false,
  },
  manager: {
    label: 'Manager',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [],
    isMultiSelect: false,
  },
  hr: {
    label: 'HR Partner',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [],
    isMultiSelect: false,
  },
  location: {
    label: 'Location',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      { label: 'INDIA', value: 'india' },
      { label: 'US', value: 'us' },
    ],
    isMultiSelect: false,
  },
  employee_type: {
    label: 'Employee Type',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      { label: 'Part Time', value: 'part_time' },
      { label: 'Full Time', value: 'full_time' },
      { label: 'Contractor', value: 'contractor' },
    ],
    isMultiSelect: false,
  },
  employee_status: {
    label: 'Employee Status',
    type: 'string',
    isRequired: true,
    isDisabled: false,
    optionList: [
      { label: 'Probation', value: 'probation' },
      { label: 'Confirmed', value: 'confirmed' },
    ],
    isMultiSelect: false,
  },
  eligible_monthly_pto: {
    label: 'Eligible Monthly PTO (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: { step: 4 },
  },
  pto_cap: {
    label: 'Maximum PTO earnable (hours)',
    type: 'number',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
    // Below should be only applied to india location
    inputProps: { step: 4 },
  },
  date_of_birth: {
    label: 'DOB',
    type: 'date',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
  date_of_joining: {
    label: 'Date of Joining',
    type: 'date',
    isRequired: true,
    isDisabled: false,
    optionList: null,
    isMultiSelect: false,
  },
}
