import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import DetailView from 'sections/common/DetailView'
import { Paper, Alert, Stack, Button, Typography, FormHelperText } from '@mui/material'
import { hasAccess } from 'utils'
import useAxios from 'hooks/useAxios'
import { LoadingButton } from '@mui/lab'
import RenderTextField from 'pages/Common/RenderTextField'
import {
  formStyle,
  SUBMISSION_FIELD_DEFINITION,
  INITIAL_FORM_VALUES,
  SUBMISSION_FIELD_TYPES,
  SUBMISSION_FIELDS,
} from 'pages/Consultant/utils'
import { GET_CONSULTANT_SUBMISSION, SUBMIT_SUBMISSION, CREATE_SUBMISSION, GET_CONSULTANT_VENDOR, GET_EVALUATION_DETAIL } from 'services/url'
import { isValidUUID } from 'utils/common'
import { pullJdOption } from 'services/client'

const Submission = ({ id, access, data, jobTitle, close, client, fetchData, pipelineNo }) => {
  const initForm = useCallback(
    () => INITIAL_FORM_VALUES({}, SUBMISSION_FIELD_DEFINITION),
    [SUBMISSION_FIELD_DEFINITION]
  )
  const [formValues, setFormValues] = useState([initForm()])
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState([{}])
  const [evaluationData, setEvaluationData] = useState([])
  const evaluationRequest = useAxios()
  const submissionRequest = useAxios()
  const payRateRequest = useAxios()

  const resetForm = () => {
    setDirty(false)
    setErrors([{}])
  }

  const addFormValue = () => {
    setFormValues((prev) => [...prev, initForm()])
  }

  const handleInputChange = useCallback((index, e) => {
    const { name, value } = e.target
    setFormValues((prevFormValues) =>
      prevFormValues.map((prevFormValue, ind) => (index === ind ? { ...prevFormValue, [name]: value } : prevFormValue))
    )
  }, [])

  useEffect(() => {
    pullJdOption().then((res) => {
      SUBMISSION_FIELD_TYPES.option.jd_requirement = res.data
    })
  }, [])

  useEffect(() => {
    resetForm()
    submissionRequest.sendRequest(GET_CONSULTANT_SUBMISSION(id))
    payRateRequest.sendRequest(GET_CONSULTANT_VENDOR(id))
    SUBMISSION_FIELD_TYPES.option.client_id = client
    SUBMISSION_FIELD_TYPES.option.applied_position_title = jobTitle
  }, [id, client, jobTitle])

  useEffect(() => {
    if (submissionRequest.data) {
      const submissionData = Array.isArray(submissionRequest.data) ? submissionRequest.data : [submissionRequest.data]
      setFormValues(submissionData.map((submission) => INITIAL_FORM_VALUES(submission, SUBMISSION_FIELD_DEFINITION)))
    } else if (submissionRequest.error) {
      console.error(submissionRequest.error)
    }
    resetForm()
  }, [submissionRequest.data, submissionRequest.error])

  useEffect(() => {
    if (payRateRequest.data) {
      SUBMISSION_FIELD_TYPES.option.vendor_consultant_id = payRateRequest.data.map((item) => ({
        label: `${item.vendor_name} : ${item.pay_rate}`,
        value: item.id,
      }))
    } else if (payRateRequest.error) {
      console.error(payRateRequest.error)
    }
    resetForm()
  }, [payRateRequest.data, payRateRequest.error])

  const validateForm = (index) => {
    const formErrors = {}
    let isValid = true
    const formData = { ...formValues[index] }

    SUBMISSION_FIELDS.forEach((field) => {
      if (SUBMISSION_FIELD_TYPES.required.includes(field[0]) && !formData[field[0]]) {
        formErrors[field[0]] = `${field[1]} is required`
        isValid = false
      }
    })

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors]
      newErrors[index] = formErrors
      return newErrors
    })

    return isValid
  }
  useEffect(() => {
    evaluationRequest.sendRequest(GET_EVALUATION_DETAIL(pipelineNo))
  }, [pipelineNo])
  
  useEffect(() => {
    if (evaluationRequest.data) {
      setEvaluationData(evaluationRequest.data)
    }
  }, [evaluationRequest.data])

  const handleSubmit = (index) => {
    if (evaluationData.length === 0) {
      alert('No evaluations available. Please evaluate before submitting.')
      return
    }
    setDirty(true)
    if (validateForm(index)) {
      const submissionData = formValues[index]
      if (!isValidUUID(submissionData.id)) {
        delete submissionData.id
      }

      const endpoint = submissionData.id ? SUBMIT_SUBMISSION(submissionData.id) : CREATE_SUBMISSION
      const method = submissionData.id ? 'PUT' : 'POST'

      submissionRequest
        .sendRequest(endpoint, true, method, { ...submissionData, consultant_id: id })
        .then(() => {
          alert('Operation Successful')
          fetchData()
          // NOTE: not sure its good UX for closing modal
          close()
        })
        .catch((error) => {
          console.error('Submission Error:', error)
        })
    }
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(data).length}
      onClose={close}
      title="Submission"
      body={
        <>
          <FormHelperText
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'red',
              backgroundColor: 'white',
              my: 2,
              borderRadius: 2,
              py: 2,
            }}
          >
            "Applied Position title" will be phased out soon; Instead, use "Applied Jd Req" which depends on Active JD
            requirements
          </FormHelperText>
          {formValues.length === 0 ? (
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: 1 }}>
              <Typography textAlign={'center'}>No Submission For this Candidate so far</Typography>
            </Paper>
          ) : (
            formValues.map((formValue, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  padding: '20px',
                  marginBottom: 1,
                  border: `${new Date(formValue?.last_working_date) > new Date() ? '' : '1px solid red'}`,
                }}
              >
                <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
                  {SUBMISSION_FIELDS.map((field) => (
                    <RenderTextField
                      key={field[0]}
                      name={field[0]}
                      label={field[1]}
                      formValues={formValue}
                      handleInputChange={(e) => handleInputChange(index, e)}
                      errors={errors[index] || {}}
                      fields={SUBMISSION_FIELD_TYPES}
                    />
                  ))}
                </form>
                {hasAccess(access, 'edit') && (
                  <Stack direction="row" justifyContent="space-between">
                    {dirty && errors[index] && Object.keys(errors[index]).length > 0 ? (
                      <Alert severity="error">Add Required Fields on Submission Form</Alert>
                    ) : (
                      <Stack />
                    )}
                    <LoadingButton
                      size="small"
                      variant="contained"
                      loading={submissionRequest.loading}
                      onClick={() => handleSubmit(index)}
                    >
                      Save Submission
                    </LoadingButton>
                  </Stack>
                )}
              </Paper>
            ))
          )}
        </>
      }
      actions={
        hasAccess(access, 'edit') && (
          <Button size="small" variant="contained" onClick={addFormValue}>
            Add
          </Button>
        )
      }
    />
  )
}

Submission.propTypes = {
  access: PropTypes.number,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  jobTitle: PropTypes.array.isRequired,
  client: PropTypes.array.isRequired,
}

export default Submission
