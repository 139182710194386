import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Stack, Typography, Button, Card, Alert } from '@mui/material'
import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
// import Toolbar from 'sections/table/Toolbar'
import useAxios from 'hooks/useAxios'
import { LIST_USER } from 'services/url'
import DetailView from 'pages/User/NewDetailView'
import RoleDetailView from 'pages/User/DetailView'
import useHandleSelect from 'hooks/useHandleSelect'
import { Helmet } from 'react-helmet-async'
import { USER } from 'utils/helmet'
import SimpleToolbar from 'sections/table/SimpleToolbar'

export default function ListPage({ access }) {
  const isEmployeeScreen = window.location.pathname === '/employees'
  const columns = useMemo(
    () => [
      { id: 'employee_id', label: 'Emp ID' },
      { id: 'first_name', label: 'First Name' },
      { id: 'manager', label: 'Manager' },
      { id: 'job_title', label: 'Job Title' },
    ],
    []
  )

  const [data, setData] = useState([])
  const [selectedData, setSelectedData] = useState({})
  const [roleEdit, setRoleEdit] = useState({})
  const [selected, setSelected] = useState([])
  const [paginator, setPaginator] = useState({ page: 1, rowsPerPage: 10, totalCount: 0 })

  const userRequest = useAxios()
  const handleSelect = useHandleSelect(setSelected)

  const onFilterChange = useCallback(() => {}, [])

  const fetchListOfUser = useCallback(() => {
    const getUserList = LIST_USER(paginator.rowsPerPage, paginator.page)
    userRequest.sendRequest(getUserList)
  }, [paginator.page, paginator.rowsPerPage, userRequest])

  const handleNewUserClick = useCallback(() => {
    setSelectedData({ email: '' })
  }, [])

  const handleCloseDetailPage = useCallback(() => {
    setSelectedData({})
    setRoleEdit({})
  }, [])

  const customHandler = isEmployeeScreen
    ? []
    : [
        {
          label: 'Mange Roles',
          icon: 'simple-icons:authelia',
          action: (data) => {
            setRoleEdit(data)
          },
        },
      ]

  useEffect(() => {
    fetchListOfUser()
  }, [paginator.page, paginator.rowsPerPage])

  useEffect(() => {
    if (userRequest.data) {
      setData(userRequest.data.results)
      setPaginator((prevPaginator) => ({ ...prevPaginator, totalCount: userRequest.data.count }))
    } else if (userRequest.error) {
      console.error('Something is not correct with the user request')
    }
  }, [userRequest.response])

  return (
    <>
      <Helmet>
        <title> {USER} </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">{isEmployeeScreen ? 'Employees' : 'Users'}</Typography>
          <Button
            onClick={handleNewUserClick}
            variant="contained"
            color="inherit"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New {isEmployeeScreen ? 'Employee' : 'User'}
          </Button>
        </Stack>

        <Card>
          <SimpleToolbar numSelected={0} totalCount={paginator.totalCount} />
          {data.length > 0 ? (
            <TableView
              data={data}
              columns={columns}
              selected={selected}
              handleSelect={handleSelect}
              paginator={paginator}
              setPaginator={setPaginator}
              handleEdit={setSelectedData}
              customHandler={customHandler}
            />
          ) : (
            <Typography variant="h6" textAlign="center" p={5}>
              Loading...
            </Typography>
          )}
        </Card>
        <DetailView
          key={selected?.id}
          access={access}
          data={selectedData}
          fetchData={fetchListOfUser}
          close={handleCloseDetailPage}
        />
        <RoleDetailView
          key={roleEdit?.id}
          access={access}
          data={roleEdit}
          fetchData={fetchListOfUser}
          close={handleCloseDetailPage}
        />
      </Container>
    </>
  )
}
