import { buildOptions } from 'utils'
import { makeAPIRequest } from './index'

// export function downloadMonthlyHrReport(month) {
//   return makeAPIRequest(`/api/hr-report?month=${month}&year=2024`, buildOptions('GET'), true)
// }

export function downloadMonthlyHrReport(startDate, endDate) {
  const formattedStartDate = encodeURIComponent(startDate)
  const formattedEndDate = encodeURIComponent(endDate)

  return makeAPIRequest(`/api/hr-report?start_date=${formattedStartDate}&end_date=${formattedEndDate}`, buildOptions('GET'), true)
}
