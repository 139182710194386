import React, { useEffect, useState, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Stack, Typography, Button, Card } from '@mui/material'

import Iconify from 'components/iconify'
import TableView from 'sections/table/TableView'
import SimpleToolbar from 'sections/table/SimpleToolbar'
import CustomInput from 'sections/common/CustomInput'
import useAxios from 'hooks/useAxios'
import { hasAccess } from 'utils'
import { LMS } from 'utils/helmet'
import { LIST_LMS_USER } from 'services/url'
import { getLmsDetails, listLeaveRequest } from 'services/lms'

import DetailView from 'pages/Lms/DetailView'
import PublicHoliday from 'pages/Lms/PublicHoliday'
import MonthlyReport from 'pages/Common/MonthlyReport'

export default function LmsListPage({ access }) {
  const [showPublicHoliday, setShowPublicHoliday] = useState(false)
  const [searchEmail, setSearchEmail] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [emailOption, setEmailOption] = useState([])
  const [data, setData] = useState([])
  const [leaveRequests, setLeaveRequests] = useState([])
  const leaveManagementRequest = useAxios()
  const [selfManageLeave, setSelfManageLeave] = useState({})
  const [showLeaveDetails, setShowLeaveDetails] = useState(false)

  const handleChange = (e) => setSearchEmail(e.target.value)

  const fetchAll = () => leaveManagementRequest.sendRequest(LIST_LMS_USER('2024'))
  const fetchMyLeave = () => getLmsDetails('2024').then((res) => setSelfManageLeave(res.data))
  const currentYear = new Date().getFullYear()

  const fetchList = () => {
    listLeaveRequest(currentYear).then((res) => {
      setLeaveRequests(res.data)
    })
  }

  useEffect(() => {
    fetchAll()
    fetchMyLeave()
    // fetchList()
  }, [])

  useEffect(() => {
    if (leaveManagementRequest.data) {
      setEmailOption(
        leaveManagementRequest.data.map((data) => ({
          label: data.user_email.split('@')[0],
          value: data.user_email,
        }))
      )
      setData(leaveManagementRequest.data)
    }
  }, [leaveManagementRequest.data])

  const resetFilter = () => setSearchEmail('')

  const columnsLms = useMemo(
    () => [
      { id: 'author', label: 'Author', alignRight: true, align: 'start' },
      { id: 'start_date', label: 'Start Date', alignRight: true, align: 'start' },
      { id: 'end_date', label: 'End Date', alignRight: true, align: 'start' },
      { id: 'hours', label: 'Hours', alignRight: true, align: 'start' },
      { id: 'leave_type', label: 'Leave Type', alignRight: true, align: 'start' },
      { id: 'status', label: 'Status', alignRight: true, align: 'start' },
      { id: 'reason', label: 'Reason', alignRight: true, align: 'start' },
    ],
    []
  )

  const columns = useMemo(
    () => [
      { id: 'user_email', label: 'User', alignRight: true, align: 'start' },
      { id: 'remaining_pto', label: 'Remaining PTO' },
      { id: 'total_earned_pto', label: 'Total Earned PTO' },
      { id: 'earned_compensation', label: 'Unused Earned Compensation' },
    ],
    []
  )

  const filteredData = useMemo(() => {
    if (searchEmail) {
      return data.filter((item) => item.user_email.includes(searchEmail))
    }
    return data
  }, [data, searchEmail])

  const renderMainContent = useMemo(() => {
    if (leaveManagementRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }
    if (filteredData.length > 0) {
      return (
        <TableView
          hidePagination
          data={filteredData}
          columns={columns}
          selected={[]}
          handleSelect={() => {}}
          paginator={{}}
          setPaginator={() => {}}
          handleEdit={setSelectedData}
        />
      )
    }
    return (
      <Typography variant="body1" textAlign="center" p={1} pb={2}>
        No resource mapped under you
      </Typography>
    )
  }, [filteredData, leaveManagementRequest.loading])

  const renderListContent = useMemo(() => {
    if (leaveManagementRequest.loading) {
      return (
        <Typography variant="h6" textAlign="center" p={5}>
          Loading...
        </Typography>
      )
    }
    if (leaveRequests.length > 0) {
      return (
        <TableView
          hidePagination
          data={leaveRequests}
          columns={columnsLms}
          selected={[]}
          handleSelect={() => {}}
          paginator={{}}
          setPaginator={() => {}}
          handleEdit={setSelectedData}
        />
      )
    }
    return (
      <Typography variant="body1" textAlign="center" p={1} pb={2}>
        No Data Found
      </Typography>
    )
  }, [leaveRequests, leaveManagementRequest.loading])

  return (
    <>
      <Helmet>
        <title>{LMS}</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Leave Management System</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            {selfManageLeave && Object.keys(selfManageLeave).length > 0 && (
              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button sx={{ mr: 2 }} color="primary" variant="contained" onClick={() => setShowPublicHoliday(true)}>
                  Public Holidays
                </Button>
                <Button
                  sx={{ mr: 2 }}
                  color="primary"
                  variant="contained"
                  onClick={() => setSelectedData(selfManageLeave)}
                >
                  Manage My Leave
                </Button>
                <Button sx={{ mr: 2 }} color="primary" variant="contained" onClick={() => setShowLeaveDetails(true)}>
                  View Request Leave Details
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          <MonthlyReport access={access} />
        </Stack>
        {emailOption.length > 0 && (
          <Card sx={{ mb: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} px={2} gap={2}>
              <CustomInput
                key="email"
                name="email"
                formValue={searchEmail}
                label="Select Users to Filter"
                type="string"
                isRequired={false}
                isDisabled={false}
                optionList={emailOption}
                isMultiSelect={false}
                handleInputChange={handleChange}
              />
              <Button
                sx={{ mx: 1 }}
                color="warning"
                variant="contained"
                onClick={resetFilter}
                startIcon={<Iconify icon="eva:close-fill" />}
              >
                Reset
              </Button>
            </Stack>
          </Card>
        )}
        {!showLeaveDetails && (
          <Card>
            <SimpleToolbar numSelected={0} totalCount={filteredData.length} />
            {renderMainContent}
          </Card>
        )}
        {showLeaveDetails && (
          <Card>
            <SimpleToolbar numSelected={0} totalCount={leaveRequests.length} />
            {renderListContent}
          </Card>
        )}
        {selectedData && (
          <DetailView
            key={selectedData?.id}
            access={access}
            data={selectedData}
            fetchData={fetchAll}
            close={() => {
              setSelectedData(null)
              fetchMyLeave()
            }}
          />
        )}
        {showPublicHoliday && (
          <PublicHoliday
            key={'publicHoliday'}
            show={showPublicHoliday}
            access={access}
            close={() => {
              setShowPublicHoliday(false)
            }}
          />
        )}
      </Container>
    </>
  )
}
