import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, TextField } from '@mui/material'
import Iconify from 'components/iconify'
import { hasAccess } from 'utils'
import { downloadMonthlyHrReport } from 'services/report'
import * as XLSX from 'xlsx'

export default function MonthlyReport({ access }) {
  const isAdmin = hasAccess(access, 'admin')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0] // format: YYYY-MM-DD
    setStartDate(today)
    setEndDate(today)
  }, [])

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }

  const handleDownload = async () => {
    if (startDate && endDate) {
      try {
        const response = await downloadMonthlyHrReport(startDate, endDate)
        const formattedData = response.data
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(formattedData)
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')
        XLSX.writeFile(workbook, `Report_${startDate}_${endDate}.xlsx`)
        alert(`Report from ${startDate} to ${endDate} downloaded.`)
      } catch (error) {
        console.error('Error downloading report:', error)
      }
    } else {
      alert('Please select both start and end dates.')
    }
  }

  if (!isAdmin) return null

  return (
    <>
      {isAdmin && (
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            startIcon={<Iconify icon="eva:download-fill" />}
          >
            Report
          </Button>
        </Stack>
      )}
    </>
  )
}

MonthlyReport.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
}
