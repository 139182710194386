import { useEffect, useState, useCallback } from 'react'
import { LoadingButton } from '@mui/lab'
import { Grid, Alert, Paper, Typography } from '@mui/material'
import DetailView from 'sections/common/DetailView'
import useAxios from 'hooks/useAxios'
import { initialFormValues } from 'utils/common'
import { CREATE_UPDATE_REIMBURSEMENT } from 'services/url'
import { validateData } from 'sections/validator/supportTicket'
import model from 'sections/models/reimbursement'
import CustomInput from 'sections/common/CustomInput'
import useUser from 'hooks/useUser'

const EditPage = ({ access, selectedData, fetchData, close }) => {
  const user = useUser()
  const isSelf = selectedData?.user_email === user.email

  const isEditMode = !!selectedData?.id
  const { sendRequest, data, error, loading, method } = useAxios()
  const [dirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState(() => initialFormValues(selectedData || {}, model))

  const initFormData = useCallback(() => {
    setDirty(false)
    setErrors({})
    setFormValues(initialFormValues(selectedData || {}, model))
  }, [selectedData])

  useEffect(() => {
    initFormData()
  }, [selectedData, initFormData])

  const handleChange = (field, value) => {
    setFormValues((prevData) => ({ ...prevData, [field]: value }))
    setDirty(true)
  }

  useEffect(() => {
    if (data) {
      fetchData()
      close()
      alert('Operation successful')
    }
  }, [data])

  const reqFunc = (action) => {
    if (loading) return
    const [err, valid] = validateData(formValues, model)
    setErrors(err)
    if (!valid) return
    const payload = isEditMode ? { ...selectedData, ...formValues } : formValues
    switch (action) {
      case 'APPROVE':
        payload.approved_by = user.email
        break
      case 'REJECT':
        payload.rejected_by = user.email
        break
      case 'RECOMMEND':
        payload.recommended_by = user.email
        break
      default:
        break
    }
    sendRequest(
      CREATE_UPDATE_REIMBURSEMENT(isEditMode ? selectedData.id : ''),
      true,
      isEditMode ? 'PUT' : 'POST',
      payload
    )
  }
  const status = selectedData.approved_by
    ? 'APPROVED'
    : selectedData.rejected_by
    ? 'REJECTED'
    : selectedData.recommended_by
    ? 'PENDING APPROVAL'
    : 'NEW REQUEST'

  const color = selectedData.approved_by ? 'green' : selectedData.rejected_by ? 'red' : 'blue'

  const renderButtons = () => {
    if (selectedData.approved_by || selectedData.rejected_by) {
      return null
    }

    const commonProps = (action) => ({
      loading,
      onClick: () => reqFunc(action),
      color: 'primary',
      variant: 'contained',
    })

    if (!isEditMode || (isSelf && !selectedData?.recommended_by)) {
      return (
        <>
          <LoadingButton {...commonProps('SUBMIT')}>{isEditMode ? 'UPDATE' : 'SAVE'}</LoadingButton>
          {/* NEED TO CHECK IF WE NEED CANCEL OPTION IF SO ADD BACKEND LOGICS */}
          {/* <LoadingButton {...commonProps('CANCEL')} color="warning">
            CANCEL
          </LoadingButton> */}
        </>
      )
    }

    if (!isSelf) {
      return (
        <>
          {selectedData.recommended_by ? (
            <LoadingButton {...commonProps('APPROVE')}>Approve</LoadingButton>
          ) : (
            <LoadingButton {...commonProps('RECOMMEND')}>Recommend</LoadingButton>
          )}
          <LoadingButton {...commonProps('REJECT')} color="error">
            Reject
          </LoadingButton>
        </>
      )
    }

    return null
  }

  return (
    <DetailView
      access={access}
      open={!!Object.keys(selectedData).length}
      onClose={close}
      title="Reimbursement Detail View"
      body={
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography textAlign={'center'} variant="h6" component="h2" color={color}>
                Reimbursement Status: {status}
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={8}>
                {['title', 'reason'].map((field) => (
                  <CustomInput
                    key={field}
                    name={field}
                    formValue={formValues[field]}
                    {...model[field]}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                ))}
              </Grid>
              <Grid item xs={12} sm={4}>
                {['rate', 'category'].map((field) => (
                  <CustomInput
                    key={field}
                    name={field}
                    formValue={formValues[field]}
                    {...model[field]}
                    handleInputChange={(e) => handleChange(e.target.name, e.target.value)}
                    errors={errors}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              {['approved_by', 'recommended_by'].map(
                (field) =>
                  selectedData[field] && (
                    <CustomInput
                      key={field}
                      name={field}
                      formValue={formValues[field]}
                      {...model[field]}
                      errors={errors}
                    />
                  )
              )}
            </Grid>
          </Grid>
        </Paper>
      }
      actions={
        <>
          {dirty && Object.keys(errors).length > 0 && <Alert severity="error">Fill in all required fields</Alert>}
          {renderButtons()}
        </>
      }
    />
  )
}

export default EditPage
